import * as React from 'react';

import './footer.scss';

const Footer = () => (
  <footer className="footer">
    <div className="footer__container">
      <div className="footer__policy">Chains On Dogs: Policy ID - 13b1e946ae6fd21e77a40de3005716a753d01fd69a70f389d0fb07b3</div>
      <br />
      ©
      {' '}
      {new Date().getFullYear()}
      {' '}
      Woof Woof Woof
      {' '}
      , Built by a bunch of doggos.
    </div>
  </footer>
);

export default Footer;
